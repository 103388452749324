(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("videojs"));
	else if(typeof define === 'function' && define.amd)
		define("VideojsRecord", ["videojs"], factory);
	else if(typeof exports === 'object')
		exports["VideojsRecord"] = factory(require("videojs"));
	else
		root["VideojsRecord"] = factory(root["videojs"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_video_js__) {
return 